import { Outlet, Link, useLocation } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";

const Footer = () => {

  return (

<ScrollToTop />

    );
  };
  
export default Footer;